import React, { useState } from "react";
import WhereWeFlyMap from "../../../archive/where-we-fly-map";
import { MainHeaderGroup } from "../../../models/models";
import styles from "./sub-menu.module.scss";
import FullImageCard from "../../../../../shared/card-component/card-full-image";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import TGIcon from "../../../../../shared/tg-icon";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface SubMenuProps {
  headerGroupData: MainHeaderGroup[];
  selectedHeader: string;
  onClose: () => void;
  hoveredRegion: string;
  setHoveredRegion: any;
}

const SubMenu: React.FC<SubMenuProps> = ({
  headerGroupData,
  selectedHeader,
  onClose,
  hoveredRegion,
  setHoveredRegion,
}) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const handleMainHeaderNavigationClick = (url: string) => {
    if (url?.includes("flightstatus")) {
      navigate(`${i18n?.language}/flightstatus`);
    } else if (url?.includes("flightschedule")) {
      navigate(`${i18n?.language}/flightschedule`);
    } else if (url?.includes("e-Receipt")) {
      navigate(`${i18n?.language}/e-Receipt`);
    } else if (url?.includes("myprofile")) {
      // navigate(`${i18n?.language}/myprofile`);
      window.open(`/${i18n.language}/myprofile`, "_blank");
    } else {
      window.open(url, "_SELF");
    }
  };

  const handleSubNavigation = (e: any, url: string) => {
    if (url.includes("myprofile")) {
      if (url?.includes("?")) {
        const query = url.split("?")[1];
        window.open(`/${i18n.language}/myprofile?${query}`, "_blank");
      } else if (url.includes("mileage-statement")) {
        navigate(`${i18n?.language}/myprofile/mileage-statement`);
      }
    } else {
      window.location.href = url;
    }
  };

  return (
    useScreenSize()?.deviceSize?.width > 1080 && (
      <div className={styles.subMenu}>
        <div className={`global-content-padding ${styles.subMenuContainer}`}>
          <div className={styles.rowCustom}>
            <div className={styles.items}>
              <div className={styles.item}>
                {headerGroupData &&
                  headerGroupData.map((x, index) => (
                    <div
                      key={index}
                      className={`${styles.innerContainer} ${
                        selectedHeader === "Plan Trip" &&
                        index === 4 &&
                        styles.firstItem
                      } ${
                        selectedHeader === "Plan Trip" &&
                        index === 0 &&
                        styles.mapItem
                      } ${
                        selectedHeader === "For Business" &&
                        index === 0 &&
                        styles.businessItem
                      }`}
                    >
                      {x?.mainHeaderNavigation.map((nav, navIndex) => (
                        <div key={navIndex} className={styles.navItems}>
                          {/* Logic for world map and hover region */}
                          {nav?.showMap && (
                            <WhereWeFlyMap
                              onHoverCountry={hoveredRegion}
                              markerInfo={nav?.data}
                            />
                          )}
                          {/* logic for switching cards based on hover */}
                          {nav?.showCard &&
                            nav?.data?.length > 1 &&
                            hoveredRegion &&
                            nav?.data?.map((cardItem, index) =>
                              cardItem.regionCode === hoveredRegion ? (
                                nav?.showCard && (
                                  <FullImageCard
                                    key={index}
                                    data={{
                                      imageUrl: nav?.data[index]?.bgImageUrl,
                                      heading: nav?.data[index]?.headingText,
                                      buttonText: nav?.data[index]?.buttonText,
                                      redirectionUrl:
                                        nav?.data[index]?.redirectionUrl,
                                      type: nav?.data[index]?.cardType,
                                    }}
                                  ></FullImageCard>
                                )
                              ) : (
                                <></>
                              )
                            )}
                          {nav?.showCard &&
                            (!hoveredRegion ||
                              ["ASA", "EU", "MEA", "THA"]?.includes(
                                hoveredRegion
                              )) && (
                              <FullImageCard
                                data={{
                                  imageUrl: nav?.data[0]?.bgImageUrl,
                                  heading: nav?.data[0]?.headingText,
                                  buttonText: nav?.data[0]?.buttonText,
                                  redirectionUrl: nav?.data[0]?.redirectionUrl,
                                  type: nav?.data[0]?.cardType,
                                }}
                              ></FullImageCard>
                            )}

                          <div className={styles.textContainer}>
                            <span
                              className={styles.itemHeading}
                              onClick={() =>
                                handleMainHeaderNavigationClick(
                                  nav.mainHeaderNavigationUrl
                                )
                              }
                            >
                              {nav.mainHeaderNavigationName}
                            </span>
                            {nav?.mainHeaderSubNavigation &&
                              nav?.mainHeaderSubNavigation.length > 0 &&
                              nav?.mainHeaderSubNavigation?.map((y, yIndex) => (
                                <>
                                  {y.mainHeaderSubNavigationTitle && (
                                    <div
                                      key={yIndex}
                                      className={styles.itemContentContainer}
                                      onMouseEnter={() =>
                                        setHoveredRegion(y.regionCode)
                                      }
                                    >
                                      <a
                                        className={
                                          y.mainHeaderSubNavigationSubCategory
                                            ? styles.itemContentHeader
                                            : styles.itemContent
                                        }
                                        onClick={(e) =>
                                          handleSubNavigation(
                                            e,
                                            y.mainHeaderSubNavigationUrl
                                          )
                                        }
                                        // href={y.mainHeaderSubNavigationUrl}
                                        key={y.mainHeaderSubNavigationId}
                                      >
                                        {y.mainHeaderSubNavigationTitle}
                                        <span>
                                          {y.isExternalUrl && (
                                            <TGIcon
                                              icon={"external-link"}
                                              fillColor="#6A6D87"
                                              size="16"
                                            ></TGIcon>
                                          )}
                                        </span>
                                      </a>
                                    </div>
                                  )}
                                  {y?.mainHeaderSubNavigationSubCategory &&
                                    y?.mainHeaderSubNavigationSubCategory?.map(
                                      (z, ind) => (
                                        <div
                                          key={ind}
                                          className={
                                            styles.itemContentContainer
                                          }
                                          onMouseEnter={() =>
                                            setHoveredRegion(z.regionCode)
                                          }
                                        >
                                          <a
                                            className={styles.itemContent}
                                            href={
                                              z.mainHeaderSubNavigationSubCategoryUrl
                                            }
                                            key={
                                              z.mainHeaderSubNavigationSubCategoryId
                                            }
                                          >
                                            {
                                              z.mainHeaderSubNavigationSubCategoryTitle
                                            }
                                          </a>
                                        </div>
                                      )
                                    )}
                                </>
                              ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SubMenu;
