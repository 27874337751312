import React, { useEffect } from "react";
import styles from "./profile-contact-info.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../slice/RootReducer";
import {
  getPromotionCardDataFailure,
  getPromotionCardDataSuccess,
  promotionCardDataRequest,
} from "../../slice/promotionCardSlice";
import { PromoAdcarousel, PromotionCardData } from "../../models/models";
import TGIcon from "../../../../shared/tg-icon";
import TGPromoCarousel from "../../../../shared/tg-promo-carousel";
import { config } from "../../../../config/global";
import { Link } from "react-router-dom";

const ProfileContactInfo = () => {
  const dispatch = useDispatch();
  const promotionData = useSelector(
    (state: RootState) => state?.osciReducer?.promotionCard.promotionDetails
  );

  return (
    <>
      <div className={styles.profileContactInfo}>
        <div className={styles.profileContactInfoHeadingContainer}>
          <div className={styles.profileContactInfoHeadingText}>
            Contact Information
          </div>
          <div className={styles.profileContactInfoHeadingButtonContainer}>
            <div className={styles.profileContactInfoHeadingButton}>Edit</div>
          </div>
        </div>
        <div className={styles.profileContactInfoBodyContainer}>
          <div className={styles.profileContactInfoBodyHeadingContainer}>
            Mailing Address
          </div>
          <div className={styles.profileContactInfoHeadingText}>
            <div
              className={styles.profileContactInfoBodyMailingAddressContainer}
            >
              <div className={styles.profileContactInfoBodyAddressContainer}>
                <div className={styles.profileContactInfoLabelContainer}>
                  <div className={styles.profileContactInfoLabel}>
                    {" "}
                    Address Line 1{" "}
                  </div>
                  <div className={styles.profileContactInfoText}>
                    {" "}
                    23 Soi Ruamjairak 2{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileContactInfo;
