import { FC, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../slice/RootReducer";
import {
  getHeaderDataFailure,
  getHeaderDataSuccess,
} from "../../slice/header/header-slice";
import {
  HeaderDetails,
  MainHeaderGroup,
  MainHeaderNavigation,
  MainHeaderSubNavigation,
} from "../../models/models";
import styles from "./navigation.module.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import SearchResultWidget from "../search-result-widget";
import TGIcon from "../../../../shared/tg-icon";
import TGExpandableList, {
  ExpandableListFirstLevelView,
} from "../../../../shared/tg-expandable-list";
import SubMenu from "./sub-menu";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import ContryLanguageWidget from "../country-language-widget";
import { config } from "../../../../config/global";
import TGSearch from "../../../../shared/tg-search";
import { useTranslation } from "react-i18next";
import { useOutsideClick } from "../../../../utils/hook/useClickOutside";
import { isUserLoggedIn } from "../../../../utils/helper";
import React from "react";
import { FunctionContext } from "../header";
import MiniProfile from "../../../../shared/mini-profile";
import { setIsPanelClosed } from "../../slice/loginPanelSlice";
import { ExpandableHelp } from "../../../src/component/Help/ExpandableHelp";
import { helpDataFailure, helpDataSuccess } from "../../slice/header/helpSlice";
import { resetPersistStore } from "../../../../store";
import {
  clearSelectedFlightIndex,
  clearSelectedFlightInfoData,
  resetRedemeptionBookingData,
} from "../../slice/flightInfoSlice";

export interface closeSubMenuHandleRef {
  closeSubMenu: () => void;
}

const Navigation: React.FC<any> = React.forwardRef<closeSubMenuHandleRef, any>(
  (props, ref) => {
    useImperativeHandle(ref, () => ({
      closeSubMenu,
    }));
    const dispatch = useDispatch();
    const { userName, miniProfileClose, isHelpClicked }: any = props;
    const { data } = useSelector(
      (state: RootState) => state?.osciReducer?.header
    );
    const helpData = useSelector(
      (state: RootState) => state?.osciReducer?.help
    );

    const helpDataArray =
      helpData?.helpDetails?.mainHelpGroup?.[0]?.mainHelpNavigation;

    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
    const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

    const [headerName, setHeaderName] = useState<string>("");
    const [activeHeaderNavigation, setActiveHeaderNavigation] = useState<
      MainHeaderGroup[]
    >([]);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);
    const [hoveredRegion, setHoveredRegion] = useState<string>("");
    /// Code to handle the 2nd level navigation for mobole view -- start
    const [isExpandable, setisExpandableOpen] = useState<boolean>(false);
    const [expandableData, setExpandableData] = useState<any>({
      name: "",
      subNavigationDetails: [],
    });
    const [miniProfileShow, setMiniProfileShow] = useState<boolean>(false);

    const { i18n, t } = useTranslation();

    const searchURL =
      process.env.REACT_APP_TG_TEAMSITE_SEARCH_URL +
      i18n.language +
      config.TEAMSITE_SEARCH +
      "?query=";

    const isDesktopView: boolean = useScreenSize().deviceSize.width > 1080;

    const closeSubMenu = () => {
      isDesktopView && setIsSubMenuOpen(false);
    };

    //const containerRef = useOutsideClick(handleClickOutside);

    const handleSubNavArrowClick = (
      name: string,
      subNavigationDetails: MainHeaderSubNavigation[]
    ) => {
      setisExpandableOpen(true);
      setExpandableData({
        name: name,
        subNavigationDetails: subNavigationDetails,
      });
    };
    const handleShowHide = () => setisExpandableOpen(!isExpandable);
    ///-- end

    const handleHeaderNameHover = (
      name: string,
      navigationDetails: MainHeaderGroup[],
      isClick: boolean
    ) => {
      if (headerName === name && isClick) {
        setIsSubMenuOpen(!isSubMenuOpen);
      } else {
        setIsSubMenuOpen(true);
      }
      setHeaderName(name);
      setActiveHeaderNavigation(navigationDetails);
      setHoveredRegion("");

      //when submenu is open search should be closed - No need as of now
      //setIsSearchOpen(false);
    };

    const handleHeaderClick = (url: string) => {
      if (url) {
        window.location.href = url;
      }
    }; //mainHeaderUrl

    //To display hidden sidebar for navigation items using offCanvas
    const [show, setShow] = useState(false);

    const handleShow = () => {
      setShow(true);
      setIsSearchOpen(false);
    };
    const handleClose = () => setShow(false);

    //Dispatch headerDataRequest
    useEffect(() => {
      //dispatch(headerDataRequest());
      // new approach of using respective slice and apiSaga for teamsite communications
      var API_URL = config.TEAMSITE_HEADER_ENDPOINT;
      dispatch({
        type: config.FETCH_DATA_CONSTANT,
        url: API_URL,
        isTeamsite: true,
        successAction: getHeaderDataSuccess,
        errorAction: getHeaderDataFailure,
      });
    }, [dispatch]);

    useEffect(() => {
      if (!isDesktopView && show) {
        document.documentElement.style.setProperty("--body-overflow", "hidden");
      } else
        document.documentElement.style.setProperty("--body-overflow", "auto");
    }, [isDesktopView, show]);

    //New change to enable scroll and close submenu when scrolled
    useEffect(() => {
      const handleScroll = () => {
        setIsSubMenuOpen(false);
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    useEffect(() => {
      if (miniProfileShow) {
        document.documentElement.style.setProperty("--body-overflow", "hidden");
      } else if (!miniProfileShow) {
        document.documentElement.style.setProperty("--body-overflow", "auto");
      }
    }, [miniProfileShow]);

    // Search icon click
    const handleSearchClick = (): void => {
      setIsSearchOpen((isOpen) => !isOpen);
      setIsSubMenuOpen(false);
      setMiniProfileShow(false);
      miniProfileClose();
    };

    // Help Icon Click
    const handleHelpClick = (): void => {
      setIsHelpOpen((isHelpOpen) => !isHelpOpen);
    };

    // Dispatch help data request
    useEffect(() => {
      const API_URL = config.TEAMSITE_HELP_ENDPOINT;
      dispatch({
        type: config.FETCH_DATA_CONSTANT,
        url: API_URL,
        isTeamsite: true,
        successAction: helpDataSuccess,
        errorAction: helpDataFailure,
      });
    }, [dispatch]);

    const handleCloseSearchClick = (): void => {
      setIsSearchOpen(false);
      document.documentElement.style.setProperty("--body-overflow", "auto");
    };

    const onClickHeaderLogo = () => {
      dispatch(clearSelectedFlightInfoData());
      dispatch(clearSelectedFlightIndex());
      dispatch(resetRedemeptionBookingData());
      resetPersistStore();
      window.location.replace(window.location.origin);
    };

    const renderThaiLogo = () => {
      return (
        <Navbar.Brand
          className={styles["navigation-logo"]}
          onMouseEnter={() => {
            setIsSubMenuOpen(false);
          }}
          onTouchStart={() => {
            setIsSubMenuOpen(false);
          }}
          role="button"
          tabIndex={0}
        >
          <div
            aria-label="Thai airways Logo link to homepage"
            className="d-flex align-items-center gap-1"
            onClick={() => onClickHeaderLogo()}
          >
            <TGIcon
              icon="thai-header-logo"
              fillColor=""
              size={window.innerWidth <= 767 ? "32px" : "48px"}
            />
            <TGIcon
              icon="thai-header-text"
              fillColor=""
              size={window.innerWidth <= 767 ? "32px" : "48px"}
            />
          </div>
        </Navbar.Brand>
      );
    };

    const handleMiniProfile = () => {
      setMiniProfileShow((isOpen) => !isOpen);
      setIsSearchOpen(false);
      setIsSubMenuOpen(false);
    };

    const closeMiniProfile = () => {
      setMiniProfileShow(false);
    };

    return (
      <>
        {/* A dummy div placed to close the subMenu on hover. Need to revisit this logic */}
        <div
          className={styles.hoverControlDiv}
          onMouseEnter={() => {
            setIsSubMenuOpen(false);
          }}
          onMouseLeave={() => {
            setIsSubMenuOpen(false);
          }}
          onMouseMove={() => {
            setIsSubMenuOpen(false);
          }}
        >
          &nbsp;
        </div>
        <div className={styles.navigationContainer}>
          <Navbar
            className={`${styles.navigationContainer} ${styles["navigation-inner-container"]}`}
          >
            {renderThaiLogo()}
            {useScreenSize()?.deviceSize?.width > 1080 ? (
              <Navbar.Collapse className={styles.customCollapse}>
                <div>
                  {/* ref={containerRef} */}
                  <Nav className={styles["nav-bar-wrapper"]}>
                    {data?.headerDetails?.map(
                      (headerDetails: HeaderDetails, index: number) => (
                        <div
                          className={`nav-item ${
                            headerName === headerDetails.mainHeaderName &&
                            isSubMenuOpen
                              ? styles.selected
                              : ""
                          }`}
                          key={"nav-item_" + index}
                          role="button"
                          tabIndex={0}
                          onMouseEnter={() => {
                            if (!props.isHelpClicked) {
                              handleHeaderNameHover(
                                headerDetails?.mainHeaderName,
                                isUserLoggedIn() &&
                                  headerDetails?.mainHeaderGroupLoggedIn
                                    ?.length > 0
                                  ? headerDetails?.mainHeaderGroupLoggedIn
                                  : headerDetails?.mainHeaderGroup,
                                false
                              );
                            }
                          }}
                          onTouchStart={() => {
                            handleHeaderNameHover(
                              headerDetails?.mainHeaderName,
                              isUserLoggedIn() &&
                                headerDetails?.mainHeaderGroupLoggedIn?.length >
                                  0
                                ? headerDetails?.mainHeaderGroupLoggedIn
                                : headerDetails?.mainHeaderGroup,
                              false
                            );
                          }}
                          onClick={() =>
                            handleHeaderClick(headerDetails?.mainHeaderUrl)
                          }
                        >
                          <span
                            className={
                              headerDetails.mainHeaderId === 2 &&
                              !isSubMenuOpen &&
                              (window.location.pathname.includes(
                                "flightstatus"
                              ) ||
                                window.location.pathname.includes(
                                  "e-Receipt"
                                ) ||
                                window.location.pathname.includes("my-trips"))
                                ? styles.noWrapActive
                                : styles.noWrap
                            }
                          >
                            {headerDetails.mainHeaderName}
                          </span>
                          <div
                            className={
                              isSubMenuOpen &&
                              headerName === headerDetails.mainHeaderName
                                ? styles.headerUnderLine
                                : styles.headerUnderLineHide
                            }
                          ></div>

                          {headerDetails.mainHeaderId === 2 &&
                            !isSubMenuOpen &&
                            (window.location.pathname.includes(
                              "flightstatus"
                            ) ||
                              window.location.pathname.includes("e-Receipt") ||
                              window.location.pathname.includes(
                                "my-trips"
                              )) && (
                              <div className={styles.headerUnderLine}></div>
                            )}
                        </div>
                      )
                    )}
                    <div
                      className={styles.customSearchSign}
                      onClick={() => handleSearchClick()}
                      onMouseEnter={() => {
                        setIsSubMenuOpen(false);
                      }}
                      onTouchStart={() => {
                        setIsSubMenuOpen(false);
                      }}
                      role="button"
                      aria-label="search-icon"
                      tabIndex={0}
                    >
                      <TGIcon icon="search-icon" fillColor="" size="24" />
                      {isSearchOpen && <div className={styles.activeBar}></div>}
                    </div>
                  </Nav>
                </div>
              </Navbar.Collapse>
            ) : (
              <>
                <div className="d-flex align-items-center gap-4">
                  {isUserLoggedIn() || userName ? (
                    <div
                      className={styles.myProfile}
                      onClick={handleMiniProfile}
                    >
                      <span className={styles.myProfileLable}>
                        {t("label_myprofile")}
                      </span>
                      <TGIcon
                        icon="my-profile-down-arrow-icon"
                        size={"20px"}
                        fillColor={"none"}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => dispatch(setIsPanelClosed(true))}
                      className={styles["sign-in-icon"]}
                    >
                      <TGIcon
                        icon="sign-in-icon"
                        fillColor="#181B47"
                        size="24"
                      />
                    </div>
                  )}
                  <div
                    onClick={() => handleSearchClick()}
                    role="button"
                    tabIndex={0}
                  >
                    <TGIcon icon="search-icon" fillColor="#181B47" size="24" />
                    {isSearchOpen && <div className={styles.activeBar}></div>}
                  </div>
                  <Navbar.Toggle
                    onClick={handleShow}
                    className={styles["nav-bar-hamburger"]}
                  />
                </div>
                <Offcanvas
                  show={show}
                  onHide={handleClose}
                  className={styles["offcanvas-wrapper"]}
                >
                  <Offcanvas.Header
                    className={`${styles.offCanvasClose} global-content-padding`}
                  >
                    <Offcanvas.Title
                      className={styles["navigation-sidebar-header"]}
                    >
                      {renderThaiLogo()}
                      <span className="d-flex gap-4">
                        {isUserLoggedIn() || userName ? (
                          <div
                            className={styles.myProfile}
                            onClick={handleMiniProfile}
                          >
                            <span className={styles.myProfileLable}>
                              {t("label_myprofile")}
                            </span>
                            <TGIcon
                              icon="my-profile-down-arrow-icon"
                              size={"20px"}
                              fillColor={"none"}
                            />
                          </div>
                        ) : (
                          <div>
                            <span
                              onClick={() => {
                                dispatch(setIsPanelClosed(true));
                                handleClose();
                              }}
                              className={styles["sign-in-icon"]}
                            >
                              <TGIcon
                                icon="sign-in-icon"
                                fillColor="#181B47"
                                size="24"
                              />
                            </span>
                          </div>
                        )}
                        <span onClick={() => handleHelpClick()}>
                          <TGIcon
                            icon="help-icon"
                            fillColor="#181B47"
                            size="24"
                          />
                        </span>
                        <span
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          <TGIcon
                            icon="close-icon"
                            fillColor="#181B47"
                            size="24"
                          />
                        </span>
                      </span>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    {/* Logic for showing sub-menu and first level view on click of "->" on sub menu  */}
                    {!isExpandable && (
                      <>
                        <TGSearch
                          placeholderText={t("label_search")}
                          searchURL={searchURL}
                          isDesktopView={isDesktopView}
                        />
                        <TGExpandableList
                          expandableList={data?.headerDetails}
                          showLevel2NavArrow={true}
                          level2NavArrowClick={handleSubNavArrowClick}
                          onClose={handleClose}
                        />
                      </>
                    )}
                    {isExpandable && (
                      <>
                        <div
                          className={styles["expandable-list-back-header"]}
                          onClick={handleShowHide}
                        >
                          <TGIcon
                            icon={"arrow-left"}
                            fillColor="#381B6B"
                            size="24"
                          ></TGIcon>
                          <span>Back</span>
                        </div>
                        <div
                          className={styles["expandable-list-level2-header"]}
                        >
                          <span>{expandableData?.name}</span>
                        </div>
                        <ExpandableListFirstLevelView
                          expandableList={expandableData?.subNavigationDetails}
                          showLevel2NavArrow={false}
                          level2NavArrowClick={handleSubNavArrowClick}
                        />
                      </>
                    )}
                    {show && !isExpandable && <ContryLanguageWidget />}
                  </Offcanvas.Body>
                </Offcanvas>
              </>
            )}
          </Navbar>
          {isHelpOpen && (
            <Offcanvas
              show={show}
              onHide={handleClose}
              className={styles["offcanvas-wrapper"]}
            >
              <Offcanvas.Header
                className={`${styles.offCanvasClose} global-content-padding`}
              >
                <Offcanvas.Title
                  className={styles["navigation-sidebar-header"]}
                >
                  {renderThaiLogo()}
                  <span className="d-flex gap-4">
                    {isUserLoggedIn() || userName ? (
                      <div
                        className={styles.myProfile}
                        onClick={handleMiniProfile}
                      >
                        <span className={styles.myProfileLable}>
                          {t("label_myprofile")}
                        </span>
                        <TGIcon
                          icon="my-profile-down-arrow-icon"
                          size={"20px"}
                          fillColor={"none"}
                        />
                      </div>
                    ) : (
                      <div>
                        <span
                          onClick={() => {
                            setState(true);
                            handleClose();
                          }}
                          className={styles["sign-in-icon"]}
                        >
                          <TGIcon
                            icon="sign-in-icon"
                            fillColor="#181B47"
                            size="24"
                          />
                        </span>
                      </div>
                    )}
                    <span onClick={() => handleHelpClick()}>
                      <TGIcon icon="help-icon" fillColor="#181B47" size="24" />
                    </span>
                    <span
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <TGIcon icon="close-icon" fillColor="#181B47" size="24" />
                    </span>
                  </span>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {isHelpOpen && (
                  <>
                    <div
                      className={styles["expandable-list-back-header"]}
                      onClick={handleHelpClick}
                    >
                      <TGIcon
                        icon={"arrow-left"}
                        fillColor="#381B6B"
                        size="24"
                      ></TGIcon>
                      <span>Back</span>
                    </div>
                    <div className={styles["expandable-list-level2-header"]}>
                      <span>{helpData?.helpDetails?.mainHelpName}</span>
                    </div>
                    <ExpandableHelp
                      expandableList={helpDataArray}
                      showLevel2NavArrow={false}
                      level2NavArrowClick={handleSubNavArrowClick}
                    />
                  </>
                )}
              </Offcanvas.Body>
            </Offcanvas>
          )}
          <SearchResultWidget
            show={isSearchOpen}
            onClose={handleCloseSearchClick}
          ></SearchResultWidget>
          {miniProfileShow && (
            <FunctionContext.Provider value={closeMiniProfile}>
              <MiniProfile />
            </FunctionContext.Provider>
          )}
        </div>
        {activeHeaderNavigation && isSubMenuOpen && (
          <>
            <SubMenu
              headerGroupData={activeHeaderNavigation}
              selectedHeader={headerName}
              onClose={handleCloseSearchClick}
              hoveredRegion={hoveredRegion}
              setHoveredRegion={setHoveredRegion}
            ></SubMenu>
            <div
              className={
                isSubMenuOpen ? styles.backdropVisible : styles.backdrop
              }
              onClick={() => {
                setIsSubMenuOpen(false);
              }}
              onMouseEnter={() => {
                setIsSubMenuOpen(false);
              }}
              onTouchStart={() => {
                setIsSubMenuOpen(false);
              }}
            />
          </>
        )}
      </>
    );
  }
);

export default Navigation;
