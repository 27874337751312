import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TGRadio } from "../../../../shared/tg-radio";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import TGIcon from "../../../../shared/tg-icon";
import { Form, Offcanvas } from "react-bootstrap";
import TGButtonVariants from "../../../../shared/tg-button-variants";
import { useOutsideClick } from "../../../../utils/hook/useClickOutside";
import { CabinClass, PassengerDetails } from "../../../../utils/enum-helper";
import { RootState } from "../../slice/RootReducer";
import { useSelector } from "react-redux";
import {
  fetchOrSaveLocalizeValue,
  isEmptyChecker,
} from "../../../../utils/helper";
import styles from "./passenger-class.module.scss";

interface Props {
  handleCountSelection: (counts: any) => void;
  handleRadioChange: (data: string) => void;
  handleReset?: () => void;
  focused: any;
  setFocused: Function;
  counter: any;
  setCounter: React.Dispatch<React.SetStateAction<any>>;
  classData: any;
  setClassData: React.Dispatch<React.SetStateAction<any>>;
  sourceAirport?: any;
  tripType?: string;
  country?: string;
  isWidget?: boolean;
  widgetParams?: any;
}

const PassengerClass: React.FC<Props> = (props: Props) => {
  const { t, i18n } = useTranslation();
  const isDesktopView = useScreenSize().deviceSize.width > 767;
  const radioData = [
    {
      id: 1,
      label: t("label_economy_and_economy_plus"),
    },
    {
      id: 2,
      label: t("label_business_and_first"),
    },
  ];

  //useState variables
  const [isOpen, setIsOpen] = useState(false);
  const [isYouth, setIsYouth] = useState<boolean>(false);
  const [updatedPassengerDetails, setUpdatedPassengerDetails] = useState<any[]>(
    []
  );
  const [pClass, setPclass] = useState(t("label_economy_and_economy_plus"));
  const [pCount, setPcount] = useState<any>({
    adult: 1,
    youth: 0,
    child: 0,
    infant: 0,
  });
  const getInitialValue = () => {
    return `1 / ${pClass}`;
  };

  const [value, setValue] = useState(getInitialValue());

  //InputRef variable
  const inputRef = useRef<any>(null);

  //UseSelector variables
  const bookingWidgetField = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.fieldData
  );

  const bookingWidgetData = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget
  );
  const countryData = useSelector(
    (state: any) => state?.osciReducer?.country.CountryDetails
  );

  const passengerData = useMemo(
    () => [
      {
        id: 1,
        label: t("label_adult"),
        desc: "",
        key: PassengerDetails.Adult,
      },
      {
        id: 2,
        label: t("label_youth"),
        desc: t("label_youth_description"),
        key: PassengerDetails.Youth,
      },
      {
        id: 3,
        label: t("label_child"),
        desc: t("label_child_description"),
        key: PassengerDetails.Child,
      },
      {
        id: 4,
        label: t("label_infant"),
        desc: t("label_infant_description"),
        key: PassengerDetails.Infant,
      },
    ],
    [t]
  );

  let countryKR = props.country;

  useEffect(() => {
    const getTotalCountWithInfant = () => {
      if (
        isDesktopView &&
        bookingWidgetData.selectedRecentSearchData.from.countryCode === "GB"
      )
        return (
          bookingWidgetData.selectedRecentSearchData.passengers.adult +
          bookingWidgetData.selectedRecentSearchData.passengers.youth +
          bookingWidgetData.selectedRecentSearchData.passengers.child +
          bookingWidgetData.selectedRecentSearchData.passengers.infant
        );
      else if (
        isDesktopView &&
        bookingWidgetData.selectedRecentSearchData.from.countryCode !== "GB"
      )
        return (
          bookingWidgetData.selectedRecentSearchData.passengers.adult +
          bookingWidgetData.selectedRecentSearchData.passengers.child +
          bookingWidgetData.selectedRecentSearchData.passengers.infant
        );
      else if (
        !isDesktopView &&
        bookingWidgetData.selectedRecentSearchDataMobile.from.countryCode ===
          "GB"
      )
        return (
          bookingWidgetData.selectedRecentSearchDataMobile.passengers.adult +
          bookingWidgetData.selectedRecentSearchDataMobile.passengers.youth +
          bookingWidgetData.selectedRecentSearchDataMobile.passengers.child +
          bookingWidgetData.selectedRecentSearchDataMobile.passengers.infant
        );
      else {
        return (
          bookingWidgetData.selectedRecentSearchDataMobile.passengers.adult +
          bookingWidgetData.selectedRecentSearchDataMobile.passengers.child +
          bookingWidgetData.selectedRecentSearchDataMobile.passengers.infant
        );
      }
    };
    const getUpdatedPassengerLabelData = () => {
      if (isDesktopView) return `${getTotalCountWithInfant()} / ${pClass}`;
      else return `${getTotalCountWithInfant()} / ${pClass}`;
    };

    if (
      (isDesktopView && bookingWidgetData.selectedRecentSearchData) ||
      (!isDesktopView && bookingWidgetData.selectedRecentSearchDataMobile)
    ) {
      setValue(getUpdatedPassengerLabelData());
    }
  }, [
    bookingWidgetData.selectedRecentSearchData,
    bookingWidgetData.selectedRecentSearchDataMobile,
    isDesktopView,
    t,
    pClass,
  ]);

  useEffect(() => {
    let cabinClassData;
    const getTotalCountWithInfantWidget = () => {
      if (i18n.language?.split("-")?.[1] === "gb")
        return (
          parseInt(props?.widgetParams?.params?.passengers?.adult?.value) +
          parseInt(props?.widgetParams?.params?.passengers?.youth?.value) +
          parseInt(props?.widgetParams?.params?.passengers?.child?.value) +
          parseInt(props?.widgetParams?.params?.passengers?.infant?.value)
        );
      else {
        return (
          parseInt(props?.widgetParams?.params?.passengers?.adult?.value) +
          parseInt(props?.widgetParams?.params?.passengers?.child?.value) +
          parseInt(props?.widgetParams?.params?.passengers?.infant?.value)
        );
      }
    };
    const getUpdatedPassengerLabelDataWidget = () => {
      if (props?.widgetParams?.params?.class === CabinClass.Economy) {
        cabinClassData = t("label_economy_and_economy_plus");
      } else {
        cabinClassData = t("label_business_and_first");
      }
      return `${getTotalCountWithInfantWidget()} / ${cabinClassData}`;
    };
    if (props?.isWidget) {
      setValue(getUpdatedPassengerLabelDataWidget());
    }
  }, [props?.isWidget, bookingWidgetField?.from]);

  //UseEffect used for loading the passenger details based on Youth scenario dynamically
  useEffect(() => {
    let config: any = fetchOrSaveLocalizeValue(
      countryData,
      i18n?.language?.toLowerCase().split("-"),
      true
    );
    if (
      config &&
      (bookingWidgetField?.from?.countryCode === "GB" ||
        (props.tripType === "multicity" &&
          props?.sourceAirport?.countryCode === "GB"))
    ) {
      setUpdatedPassengerDetails(passengerData);
      setIsYouth(true);
    } else {
      let normalPassengers = passengerData.filter(
        (x: any) => x.key !== PassengerDetails.Youth
      );
      setUpdatedPassengerDetails(normalPassengers);
      setIsYouth(false);
    }
  }, [
    bookingWidgetField?.from?.countryCode,
    passengerData,
    i18n.language,
    countryData,
    props.tripType,
    props?.sourceAirport,
  ]);

  useEffect(() => {
    if (props?.classData) {
      setPclass(props.classData);
    }
    if (props?.counter) {
      setPcount(props.counter);
    }
  }, [props.classData, props.counter, isOpen]);

  useEffect(() => {
    if (
      bookingWidgetField?.passengers &&
      Object.keys(bookingWidgetField?.passengers).length !== 0
    ) {
      let count: any = 0;
      if (bookingWidgetField?.from?.countryCode === "GB") {
        count =
          bookingWidgetField?.passengers?.adult +
          bookingWidgetField?.passengers?.youth +
          bookingWidgetField?.passengers?.child +
          bookingWidgetField?.passengers?.infant;
      } else {
        count =
          bookingWidgetField?.passengers?.adult +
          bookingWidgetField?.passengers?.child +
          bookingWidgetField?.passengers?.infant;
      }

      setValue(`${count} / ${bookingWidgetField.class}`);
    }
  }, [
    bookingWidgetField?.passengers,
    bookingWidgetField?.class,
    bookingWidgetField?.from,
  ]);

  useEffect(() => {
    if (props.focused.pax) {
      inputRef.current.focus();
    }
  }, [props.focused.pax]);

  //This function handles outside click scenario, no reset done
  const handleOutside = () => {
    if (document.activeElement?.id !== "passenger_class") {
      setIsOpen(false);
      document.documentElement.style.setProperty("--inspirationdealzindex", "");
      // document.documentElement.style.setProperty(
      //   "--everymundo-z-index",
      //   "unset"
      // );
    }
  };
  const selectRef = useOutsideClick(handleOutside);

  //This is used to set the class data based on radio button interaction
  const handleClassChange = (c: any) => {
    setPclass(c?.label);
  };

  //This is used to get tht total count of the selected passengers
  const getTotalcounts = () => {
    return pCount.adult + pCount.youth + pCount.child;
  };

  //This is used to handle the increment scenario
  const handleInc = (k: any) => {
    setPcount({ ...pCount, [k]: pCount[k] + 1 });
  };

  //This handles the decrement scenario
  const handleDec = (k: any) => {
    setPcount((prevState: any) => {
      if (
        prevState[PassengerDetails.Adult] === 1 &&
        k === PassengerDetails.Adult &&
        prevState[PassengerDetails.Child] > 0
      ) {
        return {
          ...pCount,
          [k]: pCount[k] - 1,
          child: 0,
          infant: 0,
        };
      } else if (
        prevState[PassengerDetails.Infant] !== 0 &&
        k === PassengerDetails.Adult &&
        prevState[PassengerDetails.Adult] === prevState[PassengerDetails.Infant]
      ) {
        return {
          ...pCount,
          [k]: pCount[k] - 1,
          infant: pCount[PassengerDetails.Infant] - 1,
        };
      } else {
        return { ...pCount, [k]: pCount[k] - 1 };
      }
    });
  };

  //This is used to handle the disabled condition for the decrement button
  const verifyButtonDisabled = (data: any) => {
    switch (data.key) {
      case "adult":
        if (pCount[PassengerDetails.Adult] === 0) {
          return true;
        } else if (!isYouth && pCount[PassengerDetails.Adult] <= 1) {
          return true;
        } else if (
          isYouth &&
          pCount[PassengerDetails.Adult] + pCount[PassengerDetails.Youth] === 1
        ) {
          return true;
        } else {
          return false;
        }
      case "youth":
        if (pCount[PassengerDetails.Youth] === 0) {
          return true;
        } else if (
          pCount[PassengerDetails.Adult] + pCount[PassengerDetails.Youth] ===
          1
        ) {
          return true;
        } else {
          return false;
        }
      case "child":
        if (pCount[PassengerDetails.Child] === 0) {
          return true;
        } else {
          return false;
        }
      case "infant":
        if (pCount[PassengerDetails.Infant] === 0) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  };

  //This handles the disabled scenario for the increment button
  const verifyIncrementButtonDisabled = (data: any) => {
    switch (data.key) {
      case "infant":
        if (pCount[PassengerDetails.Infant] < pCount[PassengerDetails.Adult]) {
          return false;
        } else {
          return true;
        }
      case "child":
        if (pCount[PassengerDetails.Adult] !== 0 && getTotalcounts() < 9) {
          return false;
        } else if (getTotalcounts() >= 9) {
          return true;
        } else {
          return true;
        }
      default:
        if (getTotalcounts() >= 9) {
          return true;
        } else {
          return false;
        }
    }
  };

  const getTotalPassengerCount = () => {
    if (bookingWidgetField?.from?.countryCode === "GB")
      return pCount.adult + pCount.youth + pCount.child + pCount.infant;
    else {
      return pCount.adult + pCount.child + pCount.infant;
    }
  };

  //This ensures that the data is passed to the parent function once the user clicks on the Confirm button.
  const handleContinue = () => {
    setValue(`${getTotalPassengerCount()} / ${pClass}`);
    props.setClassData(pClass);
    props.setCounter(pCount);
    setIsOpen(false);
    props.handleCountSelection(pCount);
    props.handleRadioChange(pClass);
    props.setFocused({ from: false, to: false, date: false, pax: false });
    document.documentElement.style.setProperty("--carousel-z-index", "unset");
    document.documentElement.style.setProperty("--body-overflow", "auto");
    document.documentElement.style.setProperty("--inspirationdealzindex", "");
    document.documentElement.style.setProperty("--everymundo-z-index", "unset");
  };

  const handleClick = () => {
    if (isDesktopView) {
      setIsOpen((isOpen) => !isOpen);
      document.documentElement.style.setProperty(
        "--inspirationdealzindex",
        "-1"
      );
    }
  };

  const handleFocus = () => {
    setIsOpen(true);
    !isDesktopView && inputRef.current.blur();
    if (isDesktopView) {
      document.documentElement.style.setProperty(
        "--inspirationdealzindex",
        "-1"
      );
    }
  };

  //This renders the Confirm button
  const renderButton = () => {
    return (
      <TGButtonVariants
        label={t("button_confirm")}
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="30px"
        boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
        bgColor="#684B9B"
        padding="16px"
        textColor="#FFFFFF"
        fontFamily="Inter"
        fontSize="16px"
        fontStyle="normal"
        fontWeight="700"
        lineHeight="160%"
        borderDisabled="0.791px solid #E3D4FE"
        bgColorDisabled="#E3D4FE"
        boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
        bgColorHover="#684b9b"
        borderHover="0.791px solid #E3D4FE"
        boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
        width={isDesktopView ? "25%" : "100%"}
        height="50px"
        onClick={handleContinue}
      />
    );
  };

  //This renders the inner UI containing Radio section and the Passenger section
  const renderChild = () => {
    return (
      <div
        className={`d-flex ${!isDesktopView && "flex-column"} ${
          styles["passenger-class-child-wrapper"]
        }`}
      >
        <div
          className={`${styles["passenger-class-header-wrapper"]} ${styles.classContainer}`}
        >
          <div className={styles["passenger-class-header"]}>
            {t("label_class")}
          </div>
          <TGRadio
            options={radioData}
            selected={pClass === t("label_economy_and_economy_plus") ? 1 : 2}
            onChange={(opt: any) => handleClassChange(opt)}
            name="class"
            isBorder={true}
            align="column"
          />
          {countryKR?.countryCode === "KR" ? renderWarning() : ""}
        </div>
        <div className={styles.passengerDetailsClass}>
          <div className={styles["passenger-class-header"]}>
            {t("label_book_widget_passengers")}
          </div>
          {updatedPassengerDetails?.map((d: any, index: any) => (
            <div
              key={index + 1}
              className={`${styles["passenger-class-wrapper"]} d-flex align-items-center justify-content-between`}
            >
              <div className="d-flex flex-column gap-1">
                <span className={styles["passenger-class-label"]}>
                  {d.label}
                </span>
                <span className={styles["passenger-class-desc"]}>{d.desc}</span>
              </div>
              <div className="d-flex gap-5 align-items-center">
                <div className={styles["passenger-class-counter-value"]}>
                  {pCount[d.key]}
                </div>
                <button
                  className={styles["passenger-class-dec-button"]}
                  onClick={() => handleDec(d.key)}
                  disabled={verifyButtonDisabled(d)}
                >
                  <TGIcon icon="minus-icon" fillColor="" size="" />
                </button>
                <button
                  className={styles["passenger-class-inc-button"]}
                  onClick={() => handleInc(d.key)}
                  disabled={verifyIncrementButtonDisabled(d)}
                >
                  <TGIcon icon="plus-icon" fillColor="" size="24" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderWarning = () => {
    return (
      <div className={styles.warningDiv}>
        <img src="/assets/alertCircle.svg" alt="warning" />
        <span className={styles.warningText}>{t("label_korean_warning")}</span>
      </div>
    );
  };

  //This is used to show the dropdown for desktop and canvas for mobile
  const renderMenu = () => {
    return isDesktopView ? (
      <div
        className={`${styles["tg-select-passenger-menubar"]} booking-widget-overlay`}
        ref={selectRef}
      >
        {renderChild()}
        <div className={styles["passenger-class-button-wrapper"]}>
          {renderButton()}
        </div>
      </div>
    ) : (
      <div>
        <Offcanvas
          show={isOpen}
          onHide={() => {
            setIsOpen(false);
            document.documentElement.style.setProperty(
              "--body-overflow",
              "auto"
            );
            document.documentElement.style.setProperty(
              "--carousel-z-index",
              "-1"
            );
            document.documentElement.style.setProperty(
              "--inspirationdealzindex",
              ""
            );
            // document.documentElement.style.setProperty(
            //   "--everymundo-z-index",
            //   "unset"
            // );
            props.setFocused({
              from: false,
              to: false,
              date: false,
              pax: false,
            });
          }}
          placement="bottom"
          className={styles["tg-select-passenger-offcanvas"]}
          onShow={() =>
            document.documentElement.style.setProperty(
              "--body-overflow",
              "hidden"
            )
          }
        >
          <Offcanvas.Header
            closeButton
            className={styles["tg-select-passenger-offcanvas-header"]}
          >
            <Offcanvas.Title
              className={styles["tg-select-passenger-offcanvas-title"]}
            >
              {t("label_book_widget_passenger_class")}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {renderChild()}
            <div className={styles["tg-select-passenger-confirm-button"]}>
              {renderButton()}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    );
  };
  return (
    <Form.Group
      // controlId={"exampleForm.ControlInput"}
      className={styles.passengerContainer}
      title={t("label_book_widget_passenger_class")}
      role="button"
      tabIndex={0}
    >
      <span
        className={styles["tg-select-passenger-arrow-icon"]}
        onClick={() => {
          inputRef.current.focus();
        }}
      >
        <TGIcon icon="chevron-down-icon" fillColor="" size="16" />
      </span>
      <div>
        <Form.Label
          className={styles["tg-select-passenger-label"]}
          onClick={() => {
            inputRef.current.focus();
          }}
          htmlFor={t("label_book_widget_passenger_class")}
        >
          {t("label_book_widget_passenger_class")}
        </Form.Label>
        <Form.Control
          className={`${styles["tg-select-passenger-box"]}`}
          onChange={() => {}}
          ref={inputRef}
          onClick={handleClick}
          onFocus={handleFocus}
          value={value}
          readOnly={true}
          aria-label="Passenger Class"
          id="passenger_class"
        />
      </div>
      {isOpen && renderMenu()}
    </Form.Group>
  );
};

export default PassengerClass;
