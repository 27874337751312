// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Width of the scrollabr */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Height and color of the scrollbar */
::-webkit-scrollbar-thumb {
  position: absolute;
  right: 0.5rem;
  top: 2.875rem;
  height: 12.5rem;
  border-radius: 6.25rem;
  opacity: 0.4;
  background: var(--gray-icon, #6a6d87);
}

.sLXaRO1ePHU_zUuBxyDv {
  height: 95% !important;
}

.iBKFRf3cZt5zwJzz1Z3P {
  color: var(--gray-text-strong, #2c3059);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin-left: 14px;
}

@media (max-width: 991px) {
  .vkRh80_okOQjHUxZUtmz {
    max-width: 80%;
  }
}

.vkRh80_okOQjHUxZUtmz a {
  text-decoration: underline !important;
  color: #684b9b !important;
}`, "",{"version":3,"sources":["webpack://./src/OSCI/src/component/notifications/notifications.module.scss"],"names":[],"mappings":"AAAA,2BAAA;AACA;EACE,aAAA;AACF;;AACA,sCAAA;AACA;EACE,kBAAA;EACA,aAAA;EACA,aAAA;EACA,eAAA;EACA,sBAAA;EACA,YAAA;EACA,qCAAA;AAEF;;AACA;EACE,sBAAA;AAEF;;AACA;EACE,uCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;AAEF;;AAEE;EADF;IAEI,cAAA;EAEF;AACF;;AACA;EACE,qCAAA;EACA,yBAAA;AAEF","sourcesContent":["/* Width of the scrollabr */\n::-webkit-scrollbar {\n  width: 0.5rem;\n}\n/* Height and color of the scrollbar */\n::-webkit-scrollbar-thumb {\n  position: absolute;\n  right: 0.5rem;\n  top: 2.875rem;\n  height: 12.5rem;\n  border-radius: 6.25rem;\n  opacity: 0.4;\n  background: var(--gray-icon, #6a6d87);\n}\n\n.notice-bar-offcanvas {\n  height: 95% !important;\n}\n\n.notice-bar-header {\n  color: var(--gray-text-strong, #2c3059);\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 36px;\n  margin-left: 14px;\n}\n\n.notice-bar-model {\n  @media (max-width: 991px) {\n    max-width: 80%;\n  }\n}\n\n.notice-bar-model a {\n  text-decoration: underline !important;\n  color: #684b9b !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notice-bar-offcanvas": `sLXaRO1ePHU_zUuBxyDv`,
	"notice-bar-header": `iBKFRf3cZt5zwJzz1Z3P`,
	"notice-bar-model": `vkRh80_okOQjHUxZUtmz`
};
export default ___CSS_LOADER_EXPORT___;
