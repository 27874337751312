import React from "react";
import { Link } from "react-router-dom";
import styles from "./news-announcement.module.scss";
import TGIcon from "../../../../shared/tg-icon";
import { TGNewsCard } from "../../../../shared/tg-news-card";
import { AnnouncementCard } from "../../../../shared/tg-announcement-card-primary";
import { Key, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  newsDataRequest,
  newsDataSuccess,
  newsDataFailure,
} from "../../slice/newsSlice";
import { RootState } from "../../slice/RootReducer";
import { config } from "../../../../config/global";

export const NewsAnnouncements = () => {
  const dispatch = useDispatch();
  const newsDetails = useSelector(
    (state: RootState) => state?.osciReducer?.news.NewsAnnouncementDetails
  );
  const newsList = useSelector(
    (state: RootState) =>
      state?.osciReducer?.news.NewsAnnouncementDetails?.newsList
  );

  const highlightedNews = newsList?.find(
    (item: { highlighted: boolean }) => item.highlighted
  );
  const otherNews = newsList?.filter(
    (item: { highlighted: boolean }) => !item.highlighted
  );
  useEffect(() => {
    dispatch(
      newsDataRequest()
      //     {
      //     type: config.FETCH_DATA_CONSTANT,
      //     url: API_URL,
      //     isTeamsite: true,
      //     successAction: newsDataSuccess,
      //     errorAction: newsDataFailure,
      // }
    );
  }, [dispatch]);

  return (
    <div className={`${styles.newsContainer} global-content-padding `}>
      <>
        <div className={styles.newHeadingContainer}>
          <div>
            <span className={styles.headingStyle} role="heading">
              {newsDetails.title}
            </span>
          </div>
          <div>
            <Link
              className="a"
              to={newsDetails.readMoreLink}
              target="_blank"
              style={{ color: "var(--primary-text, #684B9B)" }}
            >
              <span className={styles.readMoreStyle}>
                {newsDetails.readMoreLinkText}
              </span>
              <TGIcon icon="arrow-right-sm" fillColor="" size="20" />
            </Link>
          </div>
        </div>

        <div className={styles.newsContentContainer}>
          <div className={styles.primaryNewsContainer}>
            <div className={styles.primaryNewsInnerContainer}>
              <AnnouncementCard data={highlightedNews} />
            </div>
          </div>
          <div className={styles.secondaryNewsContainer}>
            {otherNews?.map((item, index) => (
              <div className={styles.firstSecondaryNewsContainer} key={index}>
                <TGNewsCard key={index} data={[item, index]} />
              </div>
            ))}
          </div>
        </div>
      </>
    </div>
  );
};

export default NewsAnnouncements;
