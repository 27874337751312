import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TGBanner from "../../../../shared/tg-banner";
import "../../../../../src/index.scss";
import "./notifications.module.scss";
import {
  getModalDataFailure,
  getModalDataSuccess,
} from "../../slice/modalSlice";
import { RootState } from "../../slice/RootReducer";
import TGModal from "../../../../shared/tg-modal";
import {
  getNotificationDataFailure,
  getNotificationDataSuccess,
} from "../../slice/notificationSlice";
import { t } from "i18next";
import { config } from "../../../../config/global";
import styles from "./notifications.module.scss";

const Notifications: React.FC = () => {
  const dispatch = useDispatch();
  const modalSelector = useSelector(
    (state: RootState) => state?.osciReducer?.modals.data
  );
  const notificationSelector = useSelector(
    (state: RootState) => state?.osciReducer?.notification.NotificationDetails
  );
  const isLoadingModal = useSelector(
    (state: RootState) => state?.osciReducer?.modals.isLoading
  );
  const [showBanner, setShowBanner] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedContent, setSelectedContent] = useState<any>(null);
  const [currentContentIndex, setCurrentContentIndex] = useState<number>(0);
  const [modalContent, setModalContent] = useState<any>({});
  const handleCloseBanner = () => {
    setShowBanner(false);
    sessionStorage.setItem("close", "true");
  };
  const handleOpenModal = () => {
    const closed = sessionStorage.getItem("close");
    sessionStorage.removeItem("close");
    if (closed === "false" || closed === null) {
      setShowModal(true);
      setSelectedContent(notificationSelector[currentContentIndex]);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const NOTIFICATION_API_URL = config.TEAMSITE_NOTICE_BANNER_URL;
  const MODAL_API_URL = config.TEAMSITE_MODAL_URL;

  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: NOTIFICATION_API_URL,
      isTeamsite: true,
      successAction: getNotificationDataSuccess,
      errorAction: getNotificationDataFailure,
    });
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: MODAL_API_URL,
      isTeamsite: true,
      successAction: getModalDataSuccess,
      errorAction: getModalDataFailure,
    });
  }, [dispatch]);
  //show banner heading and pop up details based on interval
  useEffect(() => {
    const IntervalId = setInterval(() => {
      setCurrentContentIndex((prevIndex) =>
        prevIndex === notificationSelector?.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000);
    return () => clearInterval(IntervalId);
  }, [notificationSelector?.length]);
  useEffect(() => {
    const currentContent = notificationSelector?.[currentContentIndex];
    setModalContent(currentContent);
  }, [currentContentIndex, notificationSelector]);
  return (
    <>
      <TGBanner
        className="BannerContainer"
        contentContainer="ContentContainer"
        iconClass="IconContainer"
        textContainer="TextContainer"
        secondaryContainer="MoreDetailsContainer"
        secondaryContainerContent="MoreDetails"
        data={notificationSelector ? notificationSelector : []}
        show={showBanner}
        onClose={handleCloseBanner}
        onIconClick={handleOpenModal}
        actionText=""
        contentClass="content"
        isLoading={isLoadingModal}
      />
      {showModal && (
        <TGModal
          show={showModal}
          headingClass="headerText"
          handleClose={handleCloseModal}
          buttonPresent={false}
          customOffCanvasClass={styles["notice-bar-offcanvas"]}
          centered={true}
          customModalClass={styles["notice-bar-model"]}
          heading={t(["enroll_privary_notice"])}
        >
          <ol>
            {notificationSelector?.map((x: any) => (
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: x?.description + " " + x?.descriptionLink,
                  }}
                ></div>
                <br />
              </div>
            ))}
          </ol>
        </TGModal>
      )}
    </>
  );
};

export default Notifications;
